<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :width="flexColumnWidth(800)"
      custom-class="dialog_center"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="dialog-center-box">
        <div class="top">
          <p>查看定靶数据</p>
          <i class="el-icon-close" @click="close_dialog"></i>
        </div>
        <div class="body">
          <div class="info-box">
            <table border="1" class="qcpTargetData-table">
              <thead class="qcpTargetData-thead">
                <tr>
                  <th>机号</th>
                  <th>检测项目</th>
                  <th>质控品批号</th>
                  <th>定靶状态</th>
                  <th>定靶次数</th>
                </tr>
              </thead>
              <tbody class="qcpTargetData-tbody">
                <tr>
                  <td>{{target_info.instrument_id}}</td>
                  <td>{{target_info.testing_unique}}</td>
                  <td>{{target_info.qc_batch}}</td>
                  <td :class="is_finish==4?'success-color':'danger-color'">{{is_finish===4?'已完成':[1,2,3].includes(is_finish)?'未完成':''}}</td>
                  <td>
                    <div v-if="[3,4].includes(is_finish)">
                      {{ setting_target_times }}
                    </div>
                    <div v-else-if="[1,2].includes(is_finish)" class="input-box">
                      <el-input min="2" size="mini" type="number" :disabled="disabled_input" v-model="setting_target_times" placeholder="请输入定靶次数"></el-input>
                      <i class="el-icon-edit" @click="disabled_input=false"></i>
                      <i class="el-icon-check" @click="save_target"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="detailList-box">
            <p class="tip-title">数据明细</p>
            <div class="table-box">
              <el-table
                :data="target_data"
                border
                size="mini"
                :stripe="true"
                style="width: 100%"
                >
                <el-table-column label="序号" align="center" width="80">
                  <template slot-scope="scope">
                    {{ scope.$index+1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="date_time_order"
                  label="时间"
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  prop="c1"
                  align="center"
                  label="C1(mg/L)"
                  :width="flexColumnWidth(200)"
                  >
                  <template slot-scope="scope">
                    <span :class="scope.row.is_c1_cl?'danger-color':''">{{textTarget(scope.row.c1)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="c2"
                  align="center"
                  label="C2(mg/L)"
                  :width="flexColumnWidth(200)"
                  >
                  <template slot-scope="scope">
                    <span :class="scope.row.is_c2_cl?'danger-color':''">{{textTarget(scope.row.c2)}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="process-info-box">
            <table border="1" class="qcpTargetData-table process-info-table">
              <!-- <thead class="qcpTargetData-thead">
                <tr>
                  <th></th>
                  <th class="value-td">C1(mg/L)</th>
                  <th class="value-td">C2(mg/L)</th>
                </tr>
              </thead> -->
              <tbody class="qcpTargetData-tbody">
                <tr>
                  <td class="th no-border"><span class="xbar">X</span>:</td>
                  <td class="value-td no-left-border">{{ textTarget(target_info.target_c1) }}</td>
                  <td class="value-td no-right-border">{{ textTarget(target_info.target_c2) }}</td>
                </tr>
                <tr>
                  <td class="th no-border">SD:</td>
                  <td class="value-td no-left-border" >{{ textTarget(target_info.sd_c1) }}</td>
                  <td class="value-td no-right-border">{{ textTarget(target_info.sd_c2) }}</td>
                </tr>
                <tr >
                  <td class="th no-border">CV:</td>
                  <td class="value-td no-left-border">{{ textCV(target_info.cv_c1) }}%</td>
                  <td class="value-td no-right-border">{{ textCV(target_info.cv_c2) }}%</td>
                </tr>
                <tr>
                  <td class="th no-border">偏离数据统计:</td>
                  <td class="value-td no-left-border"> {{ target_info.deviate_c1 }} </td>
                  <td class="value-td no-right-border"> {{ target_info.deviate_c2 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="zShow&&target_data.length>=3" class="echarts-box">
            <p class="tip-title">Z分数图</p>
            <targetLine_Z id="qcpTargetDataEcharts" :ehartData="zData"></targetLine_Z>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/quality'
import targetLine_Z from '../echarts/targetLine_Z.vue'
import { flexColumnWidth } from '@/assets/js/adaption.js'
import indoorMixins from '../indoorMixins'

export default {
  mixins: [indoorMixins],
  props: ['hospital_id','nowDate'],
  components: {
    targetLine_Z
  },
  data() {
    return {
      dialogVisible: false, //弹窗
      disabled_input: true, //定靶次数可编辑
      is_finish: 1, //定靶状态 1时间不够次数不够 2时间不够次数够了 3时间够了次数不够 4时间够了次数够了
      setting_target_times: '', //定靶次数
      qc_serum_id: '',
      target_info: {},
      target_data: [],
      zData: {},
      zShow: false,
    }
  },
  methods: {
    // 表格列宽
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    /* 打开和关闭弹窗，其他页面也会调用 */
    open_dialog(qc_serum_id) {
      this.qc_serum_id = qc_serum_id
      this.dialogVisible = true
      setTimeout(()=> {
        this.loadingShow()
        this.get_qc_target().then(res => {
          this.loading.close();
        })
      },100)
    },
    close_dialog() {
      this.zShow = false
      this.dialogVisible = false
    },
    handleClose(done) {
      this.zShow = false
      done();
    },
    /* 修改定靶次数 */
    save_target() {
      if (
        Number(this.setting_target_times) >= 2 &&
        Number.isInteger(Number(this.setting_target_times))
      ) {
      } else {
        this.$message.warning('定靶次数为大于1的正整数')
        return
      }
      let param = {
        hospital_id: this.hospital_id,
        is_using_fixed: false,
        qc_serum_id: this.qc_serum_id,
        setting_target_times: this.setting_target_times,
       
      }
      this.loadingShow('保存中,请稍后');
      api.set_qc_target(param).then(res => {
        let qc_serum_id_text_list = param.qc_serum_id.split('-')
        let parentData = {
          testing_id: qc_serum_id_text_list[1],
          instrument_id: qc_serum_id_text_list[0],
        }
        this.$emit('setTargetSuccess',parentData)
        // this.$message.success('保存成功')
        // this.close_dialog();
        // this.get_qc_target();
      })
    },
    /* 获取定靶期间数据信息 */
    get_qc_target() {
      return new Promise((resolve, reject)=> {
        let param = {
          qc_serum_id: this.qc_serum_id
        }
        this.target_info = {};
        this.target_data = [];
        this.disabled_input = true;
        this.is_finish = '';
        this.setting_target_times= '';
        this.zShow = false
        api.qc_target(param).then((res) => {
          this.target_info = res.data.target_info
          let deviate_c1 = 0,
              deviate_c2 = 0
          res.data.target_data.forEach(e => {
            e.is_c1_cl?deviate_c1++ : ''
            e.is_c2_cl?deviate_c2++ : ''
          })   
          this.target_info.deviate_c1 = deviate_c1
          this.target_info.deviate_c2 = deviate_c2

          this.target_data = res.data.target_data
          this.setting_target_times = res.data.setting_target_times
          this.is_finish = res.data.is_finish
          this.zData = this.handle_dataToEcharts(this.target_data)
          this.zShow = true
          
        }).finally(msg => {
          resolve();
        })
      })
    },
    // echart 数据处理 重写了方法
    handle_dataToEcharts(Odata) {
      let arr1 = [],
          arr2 = [],
          arr4date = []
        Odata.forEach((element) => {
          arr1.push({
            value: element.normalized_c1<-3.8?
                  -3.8:element.normalized_c1>3.8?
                  3.8:element.normalized_c1,
            realValue: element.c1,
            batch: element.batch,
            cl: element.is_c1_cl,
            label: 'C1',
          })
          arr2.push({
            value: element.normalized_c2<-4.0?
                  -4.0:element.normalized_c2>4.0?
                  4.0:element.normalized_c2,
            realValue: element.c2,
            batch: element.batch,
            cl: element.is_c2_cl,
            label: 'C2',
          })
          arr4date.push(element.date_time_order)
        })
        let ehartData = {
          text: 'z分数图',
          dataX: arr4date,
          series: [
            {
              name: 'C1质控点',
              type: 'line',
              symbol: 'rect',
              symbolSize: 11,
              connectNulls: true,
              color: '#166DC9',
              data: arr1,
              itemStyle : {  
                normal : {  
                  color: (obj)=>{
                    if(obj.data.cl ){
                      return 'red' 
                    }else {
                      return obj.color
                    }
                  },  
                },
              },  
            },
            {
              name: 'C2质控点',
              type: 'line',
              symbol: 'circle',
              symbolSize: 11,
              connectNulls: true,
              color: '#7CCE7A',
              data: arr2,
              itemStyle : {  
                normal : {  
                  color: (obj)=>{
                    if(obj.data.cl){
                      return 'red' 
                    }else {
                      return obj.color
                    }
                  },  
                },
              },  
            }
          ],
          name: ['C1质控点', 'C2质控点', ],
        };
        return ehartData
    },
  

  }
}
</script>

<style lang="scss" scoped>

.success-color {
  color: #67C23A;
}
.danger-color {
  color: red;
}

::v-deep .dialog_center {
  height: px(600);
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
  }
}
.dialog-center-box {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $dialog-title-size;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: $gap;
    font-size: $dialog-text-size;
    height: px(500);
    overflow: auto;
    .info-box {
      .input-box {
        
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $gap;
        ::v-deep .el-input{
          width: px(150);
          .el-input__inner{
            width: px(150);
          }
        }
      }
    }
    .detailList-box {
      margin: $gap 0 0;
      .tip-title {
        font-size: $tip-size;
        margin-bottom: $gap/2;
      } 
      .table-box {
        ::v-deep .el-table__body {
          .has-gutter {
            th{
              .cell {
                font-size: $table-th-size;
              }
            }
          }
          .el-table__row {
            td {
              .cell {
                font-size: $table-td-size;
              }
            }
          }
        } 
      }
    }
    // 偏离值法计算出的表格
    .process-info-box {
      display: flex;
      justify-content: end;
      .value-td {
        width: px(200);
      }
      .process-info-table {
        width: 80%;
      }
      .th {
        text-align: right;
      }
      td {
        padding: $gap 0;
      }
    }
  }
}


.echarts-box {
  margin-top: $gap;
  .tip-title {
    font-size: $tip-size;
    margin-bottom: $gap/2;
  } 
}

.qcpTargetData-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: $border-color;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  tr { 
    width: 100%;
    text-align: center;
  }
  th {
    font-size: $table-th-size;
  }
 
  td {
    font-size: $table-td-size;
  }
  .no-border {
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
  }
  .no-top-border {
    border-top: 0;
  }
  .no-bottom-border {
    border-bottom: 0;
  }
  .no-left-border {
    border-left: 0;
  }
  .no-right-border {
    border-right: 0;
  }
}
.xbar {
  text-decoration: overline;
  
}
.accept-confirm-box {
  padding-left: $gap;
  .btn {

  }
}
</style>